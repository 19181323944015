<template>
  <div class="callServiceTel">
    <van-popup
      v-model="popupShow"
      closeable
      :style="{ width: '70%' }"
      round
      @close="popupClose"
    >
      <div class="callTelPopup">
        <svg-icon icon-class="kefu" class="kefuIcon"></svg-icon>
        <span class="text">联系客服</span>
        <a href="tel:4001108968" ref="telPhone" class="tellBtn">拨打电话</a>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  props: ["callTelShow"],
  data() {
    return {
      popupShow: true
    };
  },
  methods: {
    popupClose() {
      this.$emit("telHide", true);
    }
  }
};
</script>

<style lang="less" scoped>
.callTelPopup {
  padding: 0.4rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .kefuIcon {
    font-size: 1.8rem;
  }
  .text {
    font-size: 0.4rem;
    margin: 0.6rem 0 0.4rem;
  }
  .tellBtn {
    background: rgb(230, 20, 20);
    font-size: 0.4rem;
    text-align: center;
    padding: 0.3rem 0;
    border-radius: 0.6rem;
    color: #fff;
    width: 100%;
    font-weight: bold;
  }
}
</style>
