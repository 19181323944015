import request from "utils/request";
import qs from "qs";

// 选择技师列表
export function chooseArtificer(data) {
  return request({
    url: "/wap-api/artificer/chooseArtificer",
    method: "post",
    hds: 2,
    data: data
  });
}
//   获取技师时间
export function getLsArtificerAppointmentTimeOutList(query) {
  return request({
    url: "/wap-api/artificer/getLsArtificerAppointmentTimeOutList",
    method: "get",
    params: query
  });
}

// 筛选技师列表
export function screenArtificer(data) {
  return request({
    url: "/wap-api/artificer/pageArtificer",
    method: "post",
    hds: 2,
    data: data
  });
}

// 获取技师详情
export function getArtificerDetail(data) {
  return request({
    url: "/wap-api/artificer/getArtificerDetails",
    method: "post",
    hds: 2,
    data: data
  });
}

// 技师详情评论区
export function getArtificerDetailComment(data) {
  return request({
    url: "/wap-api/artificer/pageArtificerComment",
    method: "post",
    hds: 2,
    data: data
  });
}

// 地图筛选技师
export function getMapArtificerList(data) {
  return request({
    url: "/wap-api/artificer/mapFilterArtificer",
    method: "post",
    hds: 2,
    data: data
  });
}
