import { getCityCode } from "@/api/index";
import store from "../store/index";

class Location {
  constructor() {}
  // 获取当前地理位置
  getCurrentLocation(AMap, successFun, errorFun, isUpdateCityCode = false) {
    var geolocation = new AMap.Geolocation({
      enableHighAccuracy: true, // 是否使用高精度定位，默认:true
      timeout: 5000, // 超过10秒后停止定位，默认：5s
      convert: true, // 是否将定位结果转换为高德坐标
      needAddress: true, // 是否需要将定位结果进行逆地理编码操作
      GeoLocationFirst:false,
      noIpLocate:3,
      extensions: "all" // 是否需要详细的逆地理编码信息
    });
    geolocation.getCurrentPosition((status, result) => {
      if (status == "complete") {
        console.log(result)
        localStorage.setItem("addressInfo", JSON.stringify({
          citycode:result.addressComponent.citycode,
          city:result.addressComponent.city,
          district:result.addressComponent.district,
          province:result.addressComponent.province,
          street:result.addressComponent.street,
          streetNumber:result.addressComponent.streetNumber,
          location:result.position
        }));

        // 更新城市code
        if(isUpdateCityCode){
          getCityCode({ locationCode: result.addressComponent.citycode }).then(
            res => {
              localStorage.setItem("cityCode", res.data);
            }
          );
        }
        
        successFun(result);
      } else {
        errorFun();
      }
    });
  }

  //   POI查询周边地理位置
  //  本方法使用JSAPI查询周边地址，每日只有100次免费额度，建议使用 api/city.js 中 webservice 查询
  getNearbyLocation(AMap, city, address, successFun, errorFun) {
    var placeSearch = new AMap.PlaceSearch({
      city: city
    });
    placeSearch.searchNearBy(address, (status, result) => {
      //查询成功时，result 即对应匹配的 POI 信息
      console.log(result);
      if (status === "complete" && result.info === "OK") {
        console.log(result);
      } else {
        console.error("查询失败");
      }
    });
  }
}

const location = new Location();

export default location;
