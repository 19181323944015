<template>
  <div class="my">
    <div class="myInfo" @click="serviceClick('0')">
      <img
        v-if="myInfo.avatarUrl"
        :src="myInfo.avatarUrl"
        alt=""
        class="myImg"
      />
      <img src="../assets/img/df_avator.png" alt="" v-else class="myImg" />
      <div class="name-div">
        <p class="name-p">
          {{ myInfo.nickName || "未登录" }}
          <img
            v-if="myInfo.blnVip == 1"
            src="../assets/img/vip-price.png"
            alt=""
            class="vipImg"
          />
        </p>
        <p class="name-p-id" v-if="myInfo.id">ID：{{ myInfo.id }}</p>
      </div>
      <van-icon name="arrow" />
    </div>
    <div class="vip-div">
      <img src="../assets/img/crown.png" alt="" class="crownImg" />
      <span class="vipTips">蓝瑟到家会员尊享优惠</span>
      <span class="clickBtn" @click="serviceClick('9')"
        >去开通 <van-icon name="arrow"
      /></span>
    </div>
    <div class="service-div">
      <p
        v-for="item in serviceList"
        :key="item.id"
        @click="serviceClick(item.id)"
      >
        <svg-icon :icon-class="'icon' + item.id" class="serviceIcon"></svg-icon>
        <span>{{ item.name }}</span>
      </p>
    </div>
    <div class="share-div">
      <p class="title">邀请好友 得优惠券</p>
      <p class="tips">
        邀请好友下单得奖励
        <span class="tips-span" @click="serviceClick('7')"
          >去邀请 <van-icon name="arrow"
        /></span>
      </p>
    </div>
    <div class="recommend-div">
      <p class="recommend-title">
        <svg-icon icon-class="sound_left" class="soundIcon"></svg-icon>
        <span>为您推荐</span>
        <svg-icon icon-class="sound_right" class="soundIcon"></svg-icon>
      </p>
      <div class="recommend-ui">
        <div
          class="recommend-li"
          v-for="item in attendantList"
          :key="item.id"
          @click="serviceClick('8', item)"
        >
          <img class="serveImgUrl" :src="item.avatar" alt="" />
          <p class="recommend-info">
            <span class="name-status"
              ><font class="name">{{ item.nickName }}</font></span
            >
            <span
              ><van-rate
                v-model="item.star"
                size="15"
                readonly
                color="orange"
              />
              {{ item.star }}</span
            >
            <span class="plane"
              ><svg-icon icon-class="plane" class="planeIcon"></svg-icon>
              {{ item.distance }}km</span
            >
          </p>
        </div>
      </div>
    </div>
    <!-- 拨打提示弹窗 -->
    <callServiceTel
      :callTelShow="callTelShow"
      v-if="callTelShow"
      @telHide="callTelShow = false"
    />
  </div>
</template>

<script>
import callServiceTel from "@/components/callServiceTel";
import AMapLoader from "@amap/amap-jsapi-loader";
import { getLsMyProfile, pageUserRecommendedArtificer } from "@/api/my";
import Location from "@/utils/location";
import { screenArtificer, getMapArtificerList } from '@/api/service'

export default {
  components: {
    callServiceTel
  },
  data() {
    return {
      callTelShow: false,
      name: "",
      value: 3,
      myInfo: "",
      attendantList: [],
      serviceList: [
        {
          name: "我的收藏",
          id: "1"
        },
        {
          name: "我的优惠券",
          id: "2"
        },
        {
          name: "服务地址",
          icon: "icon1",
          id: "3"
        },
        {
          name: "用户反馈",
          id: "4"
        },
        {
          name: "联系客服",
          id: "5"
        },
        {
          name: "我的评价",
          id: "6"
        }
      ]
    };
  },
  created() {
    if (localStorage.getItem("authorization")) {
      this.getLsMyProfile();
    }
    const selectCityAddress = JSON.parse(sessionStorage.getItem('selectCityAddress'))
    // 如果有选择的地址，则使用选择的地址
    if (selectCityAddress) {
        // sessionStorage.removeItem('selectCityAddress')
        this.lat = selectCityAddress.cityInfo.lat
        this.lng = selectCityAddress.cityInfo.lng
        this.pageUserRecommendedArtificer()
    }else{
      this.setMap()
    }
  },
  methods: {
    // 我的服务点击
    serviceClick(id, item) {
      let pushPath, pushQuery;
      switch (id) {
        case "0": // 我的信息
          pushPath = "/my/info";
          break;
        case "1": // 收藏
          pushPath = "/my/collect";
          break;
        case "2": // 优惠券
          pushPath = "/my/coupon";
          break;
        case "3": // 服务地址
          pushPath = "/my/service-address";
          break;
        case "4": // 用户反馈
          pushPath = "/my/feedback";
          break;
        case "6": // 评价
          pushPath = "/my/evaluate";
          break;
        case "7": // 拉新
          // this.$toast("敬请期待~")
          // pushPath = "/recruit-new";
          pushPath = "/invite";
          break;
        case "8": //服务人员详情
          (pushPath = "/attendantDetails"), (pushQuery = { id: item.id });
          break;
        case "9": //开通会员
          pushPath = "/vipCenter";
          break;
        default:
          break;
      }
      if (id == "5") {
        // this.callTelShow = true;
        // 跳转外链
        window.location.href = "https://work.weixin.qq.com/kfid/kfc17cbbbde9caf850f"
      }else if(id == "8"){
        this.$router.push({ path: pushPath, query: pushQuery });
      } else {
        if (this.getwxcode(pushPath)) {
          this.$router.push({ path: pushPath, query: pushQuery });
        }
      }
    },
    // 获取个人信息
    getLsMyProfile() {
      getLsMyProfile().then(res => {
        this.myInfo = res.data;
        localStorage.setItem("userInfo", JSON.stringify(res.data));
      });
    },
    // 获取经纬度
    setMap() {
      let _this = this;
      AMapLoader.load({
        plugins: ["AMap.Geolocation"]
      }).then(AMap => {
        Location.getCurrentLocation(
          AMap,
          result => {
            _this.lng = result.pois[0].location.lng;
            _this.lat = result.pois[0].location.lat;
            _this.pageUserRecommendedArtificer();
          },
          () => {
            console.log("定位失败");
            _this.pageUserRecommendedArtificer();
          }
        );
      });
    },
    // 获取推荐
    pageUserRecommendedArtificer() {
      // let data = {
      //   lng: this.lng || "116.397455",
      //   lat: this.lat || "39.919087",
      //   pageIndex: 1,
      //   pageSize: 1000
      // };
      // pageUserRecommendedArtificer(data).then(res => {
      //   this.attendantList = res.data.records;
      // });

      let params = {
        pageIndex: 1,
        pageSize: 10,
        lng: this.lng || "116.397455",
        lat: this.lat || "39.919087",
        artificerName: ""
      };

      screenArtificer(params)
        .then(res => {
          if (res.code == 10000) {
            // this.artificerList = this.artificerList.concat(res.data.records);
            this.attendantList = res.data.records;
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<style lang="less" scoped>
.my {
  min-height: calc(100vh - 50px);
  background: rgb(244, 244, 244);
  padding-bottom: 50px;
  .myInfo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.3rem 0.2rem;
    background: white;
    position: sticky;
    top: 0;
    .myImg {
      margin-right: 0.2rem;
      width: 1.2rem;
      height: 1.2rem;
      border-radius: 50%;
    }
    .name-div {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .name-p {
        display: flex;
        align-items: center;
        font-size: 0.36rem;
        font-weight: bold;
        margin-bottom: 0.17rem;
        .vipImg {
          width: 1.5rem;
          margin-left: 0.2rem;
        }
      }
      .name-p-id {
        color: gray;
      }
    }
  }
  .vip-div {
    background: #000;
    border-radius: 0.2rem;
    margin: 0.3rem;
    padding: 0.1rem 0.3rem 0.1rem 0.4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .crownImg {
      width: 1rem;
      margin-right: 0.2rem;
    }
    .vipTips {
      flex: 1;
      color: rgba(245, 199, 113, 0.952);
    }
    .clickBtn {
      display: flex;
      align-items: center;
      padding: 0.15rem 0.3rem;
      border-radius: 0.4rem;
      font-size: 0.28rem;
      background: rgba(245, 199, 113, 0.952);
    }
  }
  .service-div {
    background: white;
    border-radius: 0.2rem;
    margin: 0.3rem;
    display: flex;
    flex-wrap: wrap;
    p {
      width: 33.333%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0.6rem 0;
      .serviceIcon {
        font-size: 1rem;
        margin-bottom: 0.4rem;
      }
    }
  }
  .share-div {
    background: white;
    border-radius: 0.2rem;
    margin: 0.3rem;
    padding: 0.5rem 0.6rem;
    .title {
      font-size: 0.5rem;
      font-weight: bold;
    }
    .tips {
      font-size: 0.28rem;
      color: grey;
      display: flex;
      align-items: center;
      margin-top: 0.15rem;
    }
    .tips-span {
      background: red;
      color: #fff;
      border-radius: 0.2rem;
      padding: 0.08rem 0.15rem;
      margin-left: 0.2rem;
    }
  }
  .recommend-div {
    border-radius: 0.2rem;
    margin: 0.3rem;
    .recommend-title {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.4rem;
      span {
        padding: 0 0.2rem;
      }
      .soundIcon {
        font-size: 0.6rem;
      }
    }
    .recommend-ui {
      display: flex;
      flex-wrap: wrap;
      .recommend-li {
        width: 49%;
        background: #fff;
        border-radius: 0.2rem;
        padding: 0.3rem;
        box-sizing: border-box;
        margin-top: 0.4rem;
        &:not(:nth-child(2n)) {
          margin-right: 2%;
        }
        .serveImgUrl {
          width: 100%;
          max-height: 4rem;
        }
        .recommend-info {
          display: flex;
          flex-direction: column;
          span {
            padding-top: 0.2rem;
          }
          .name-status {
            display: flex;
            align-items: center;
            .name {
              font-size: 0.4rem;
            }
            .status {
              margin-left: 0.2rem;
              background: rgb(0, 219, 113);
              color: #fff;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 1rem;
              padding: 0.06rem 0;
              font-size: 0.28rem;
              border-radius: 0px 10px 10px 10px;
            }
          }
          .plane {
            display: flex;
            color: grey;
          }
        }
      }
    }
  }
}
</style>
